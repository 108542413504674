<script>
import { format, parseISO } from 'date-fns';

export default {
  data() {
    return {
      files: {
        reference: null,
        referenceProgress: null,
        referenceSuccess: false,
        noReference: null,
        noReferenceProgress: null,
        noReferenceSuccess: false,
      },
      menu: false,
      replace: true,
      valid: true,
      analysisDate: null,
      filesUploaded: [],
      uploadingState: false,
    };
  },
  computed: {
    hasFiles() {
      return Boolean(this.files.reference || this.files.noReference);
    },
    analysisDateRules() {
      const rules = [];

      rules.push(
        v =>
          (v && v.match(/^\d{4}-\d{2}-\d{2}$/)) ||
          'Date must be in the format YYYY-MM-DD'
      );

      return rules;
    },
    fileRules() {
      const rules = [];

      // rules.push(v => {
      //   console.log(v);
      //   return Boolean(v && v.size) || 'File is required';
      // });

      return rules;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
  },
  mounted() {
    this.analysisDate = format(
      parseISO(new Date().toISOString()),
      'yyyy-MM-dd'
    );
    // this.validate();
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    validate() {
      this.$refs.uploadForm.validate();
    },
    reset() {
      this.analysisDate = format(
        parseISO(new Date().toISOString()),
        'yyyy-MM-dd'
      );
      this.replace = true;
      this.files.noReference = null;
      this.files.reference = null;
    },
    async submit() {
      const formData = new FormData();
      formData.append('analysisDate', this.analysisDate);
      formData.append('replace', this.replace);

      const fileCategoriesToUpload = {};

      fileCategoriesToUpload.reference = Boolean(this.files.reference);
      fileCategoriesToUpload.noReference = Boolean(this.files.noReference);

      this.filesUploaded = Object.keys(fileCategoriesToUpload).map(
        fileCategory => this.uploadFileAsPromise(fileCategory)
      );

      this.uploadingState = 'Uploading...';
      await Promise.all(this.filesUploaded);
      this.uploadingState = 'Transferring...';
      this.uploadingState = 'Upload';
    },
    uploadFileAsPromise(fileCategory) {
      if (fileCategory !== 'reference' && fileCategory !== 'noReference') {
        return;
      }

      const files = this.files;
      const file = files[fileCategory];

      console.log({ fileCategory: file });

      return new Promise((resolve, reject) => {
        console.log(this.$storage(`gs://grfn-file-uploads`));

        if (!file) {
          resolve();
          return;
        }

        const storageRef = this.$storage(`gs://grfn-file-uploads`).ref();
        const dataQualityRef = storageRef.child('data-quality');
        const fileRef = dataQualityRef.child(file.name);
        const task = fileRef.put(file);

        task.on(
          'state_changed',
          function progress(snapshot) {
            void snapshot;
            // const percentage =
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // this.progress.value = percentage;

            files[`${fileCategory}Progress`] = true;
          },
          function error(err) {
            files[`${fileCategory}Progress`] = true;
            files[`${fileCategory}Success`] = false;

            reject(err);
          },
          function complete() {
            files[`${fileCategory}Progress`] = false;
            files[`${fileCategory}Success`] = true;

            // const downloadURL = task.snapshot.downloadURL;
            // this.filesUploaded.push({ fileCategory, downloadURL });

            console.log({
              fileCategory,
              downloadURL: task.snapshot.metadata,
            });
            resolve();
          }
        );
      });
    },
  },
};
</script>

<template>
  <div>
    <v-card>
      <v-card-title>Navisworks export CSV Uploads</v-card-title>
      <v-card-text>
        <v-form ref="uploadForm" v-model="valid" @submit.prevent="submit">
          <v-row
            ><v-col>
              <v-file-input
                v-model="files.reference"
                class="mb-4"
                accept=".csv"
                label="Has Reference"
                dense
                :rules="fileRules"
                :loading="files.referenceProgress"
                :success="files.referenceSuccess"
                show-size
                truncate-length="25"
                prepend-icon="mdi-file-delimited"
                @change="validate"
              >
                <template #selection="{ text }">
                  <v-chip small label color="secondary">
                    {{ text }}
                  </v-chip>
                </template></v-file-input
              >
            </v-col>
            <v-col>
              <v-file-input
                v-model="files.noReference"
                class="mb-4"
                accept=".csv"
                :loading="files.noReferenceProgress"
                :success="files.noReferenceSuccess"
                label="No Reference"
                dense
                :rules="fileRules"
                show-size
                truncate-length="25"
                prepend-icon="mdi-file-delimited"
                @change="validate"
              >
                <template #selection="{ text }">
                  <v-chip small label color="secondary">
                    {{ text }}
                  </v-chip>
                </template></v-file-input
              ></v-col
            ></v-row
          >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="analysisDate"
                label="Analysis date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="analysisDate" @change="save" />
          </v-menu>

          <v-checkbox
            v-model="replace"
            :value="true"
            label="Replace previous uploads with same date."
          />

          <v-btn
            color="secondary"
            class="mr-4"
            :disabled="
              !valid ||
              !hasFiles ||
              uploadingState === 'Uploading...' ||
              uploadingState === 'Transferring...'
            "
            type="submit"
            >{{ uploadingState || 'Upload' }}</v-btn
          >
          <v-btn @click="reset">Reset</v-btn>
        </v-form></v-card-text
      >
    </v-card>
  </div>
</template>

<style lang="scss" scoped></style>
